<template>
  <v-navigation-drawer
    :value="sideBar"
    fixed
    app
    @input="setSideBar($event)"
    style="border-radius: 0px 15px 15px 0px; box-shadow: 2px 2px 15px #cecece"
    color="#ffffff"
    width="290"
  >
    <template v-slot:prepend>
      <div class="pa-5">
        <v-app-bar-nav-icon
          color="black"
          @click.stop="setSideBar(!sideBar)"
        ></v-app-bar-nav-icon>
      </div>
      <v-list-item two-line>
        <v-img class="mb-15" src="@/assets/img/Capa_1.svg"></v-img>
      </v-list-item>
    </template>

    <v-list dense nav>
      <span v-for="(item, i) in menu" :key="i">
        <v-list-group
          active-class="selectedBgPurple--text"
          v-if="item.childrens && item.childrens.length"
        >
          <template v-slot:activator>
            <v-list-item-title
              style="
                color: #313945;
                font-size: 0.8125rem;
                font-weight: 500 !important;
                line-height: 1rem;
              "
            >
              {{ item.nombre }}
            </v-list-item-title>
          </template>
          <template #prependIcon>
            <v-icon color="#313945">{{ item.icono }}</v-icon>
          </template>
          <div>
            <v-list-item
              v-for="(child, j) in item.childrens"
              :key="j"
              :to="child.uri"
              active-class="selectedBgPurple--text"
            >
              <v-list-item-icon>
                <v-icon color="#313945">{{ child.icono }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                style="
                  color: #313945;
                  font-size: 0.8125rem;
                  font-weight: 500 !important;
                "
              >
                {{ child.nombre }}
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list-group>
        <v-list-item
          v-else
          :href="item.uri"
          active-class=" selectedBgPurple--text"
        >
          <v-tooltip right :disabled="!sideBar" color="#313945">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon color="#313945">{{ item.icono }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ item.nombre }}</span>
          </v-tooltip>

          <v-list-item-title
            style="
              color: #313945;
              font-size: 0.8125rem;
              font-weight: 500 !important;
            "
          >
            {{ item.nombre }}
          </v-list-item-title>
        </v-list-item>
      </span>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "AppAsideBar",
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    ...mapMutations("utils", ["setSideBar"])
  },
  computed: {
    ...mapState("utils", ["sideBar"])
  }
};
</script>

<style scoped>
:deep(.v-list-group__items) {
  flex: 1 1 auto;
  /* background-color: #f5f9ff; */
  background-color: #ffff;
  border-radius: 10px;
}

:deep(.mdi-chevron-down::before) {
  content: "\F0140";
  color: #313945;
}
</style>
