import pathServices from "../../services/path.services";
import rolesServices from "../../services/roles.services";

export default {
  namespaced: true,
  state: {
    alert: {
      show: false,
      type: "info",
      message: "",
      timeout: 2500,
    },
    loader: false,
    sideBar: true,
    menu: [],
    rutas: [],
    roles: [],
  },
  getters: {
    getAlert: (state) => {
      return state.alert;
    },
    obtenerRoles: (state) => {
      return state.roles;
    },
  },
  mutations: {
    setAlert: (state, payload) => {
      state.alert = payload;
    },
    setLoader: (state, payload) => {
      state.loader = payload;
    },
    setSideBar: (state, payload) => {
      state.sideBar = payload;
    },
    setMenu: (state, menu) => {
      state.menu = menu;
    },
    setRutas: (state, rutas) => {
      state.rutas = rutas;
    },
    setRoles: (state, roles) => {
      state.roles = roles;
    },
  },
  actions: {
    getMenu: async ({ commit }) => {
      const response = await this.services.menu.listarMenu();
      const menu = response?.data;
      commit("setMenu", menu);
      commit("setRutas", response.data);
    },
    getRoles: async ({ commit }) => {
      const roles = await rolesServices.getRolesUser();
      commit("setRoles", roles.data);
    },
    clearMenu: ({ commit }) => {
      // commit("setMenu", []);
      // commit("setRutas", []);
    },
  },
};

const getNavItem = (items) => {
  return items.filter((item) => {
    if (!item.mostrar) return false;
    if (item.childrens?.length > 0) item.childrens = getNavItem(item.childrens);
    return true;
  });
};
