<template>
  <div>
    <v-app-bar app :elevation="0" color="bgWhite" prominent>
      <v-app-bar-nav-icon class="black--text mt-2" v-if="!sideBar"
        @click.stop="setSideBar(!sideBar)"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="mt-5 d-flex align-center">
        <v-row no-gutters align="center" justify="center">
          <v-col :cols="breakpoint() ? col1Breakpoint : 12" :sm="breakpoint() ? 7 : 12">
            <div class="d-flex flex-column align-center d-inline-block">
              <v-menu offset-y transition="scroll-y-transition" rounded="lg">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" elevation="" text small class="mr-3">
                    <v-icon left color="btnAzul--text">mdi-menu-down-outline</v-icon>
                    <p class="mr-5 mt-4 font-weight-bold btnAzul--text">
                      {{ getFullName?.toUpperCase() }}
                    </p>
                  </v-btn>
                </template>
                <v-list dense nav>
                  <v-list-item @click="openModalCambioPassword()" class="my-0">
                    <v-list-item-icon>
                      <v-icon size="20">mdi-shield-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Cambiar contraseña</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="cerrarSession()" class="my-0">
                    <v-list-item-icon>
                      <v-icon size="20">mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Cerrar sesión</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-card-title
                class="text-caption grey--text text--darken-2 wrap-text-sub font-weight-medium py-0 pt-1 px-2"
                v-text="getPerfil" style="color: #1C1E4D !important;"
              ></v-card-title>
            </div>
          </v-col>
          <v-col v-if="breakpoint()" :cols="col2Breakpoint" sm="5">
            <div class="circular-container" v-show="breakpoint()">
              <v-icon size="50" color="#ffff">mdi-account-outline</v-icon>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app-bar>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          Cambio de contraseña
        </v-card-title>

        <v-card-text>
          <v-text-field outlined type="password" label="Contraseña actual *" v-model="form.password" />
          <v-text-field outlined type="password" label="Nueva contraseña *" v-model="form.newPassword" />
          <v-text-field outlined type="password" label="Confirmación de la nueva contraseña *"
            v-model="form.confirmPassword" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="flex flex-row justify-center gap-4">
          <v-btn color="secondary" rounded style="min-width: 35%;" @click="dialog = false"
            v-if="!loading">Cancelar</v-btn>
          <v-btn color="btnAzul" rounded style="min-width: 35%; color: white;" @click="cambiarPassword()"
            :loading="loading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "AppHeader",
  data() {
    return {
      dialog: false,
      form: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      loading: false,
    };
  },
  methods: {
    ...mapMutations("utils", ["setSideBar", "setMenu", "setRoles"]),
    ...mapMutations(["setToken"]),
    async cerrarSession() {
      let token = localStorage.getItem("token");
      this.setMenu([]);
      this.setToken(null);
      this.setRoles([]);
      try{
        const response = await this.services.auth.logout(token);
        localStorage.clear();
        this.$router.push({ name: 'login' }).catch((e) => {
        });
      } catch (e) {
        console.log(e);
      }
    },
    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return false;
      } else if (this.$vuetify.breakpoint.xs) {
        return false;
      } else if (this.$vuetify.breakpoint.md) {
        return true;
      } else if (this.$vuetify.breakpoint.lg) {
        return true;
      } else if (this.$vuetify.breakpoint.xl) {
        return true;
      } else {
        return true;
      }
    },

    openModalCambioPassword() {
      this.dialog = true;
    },

    async cambiarPassword() {
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.temporalAlert({
          show: true,
          message: "Las contraseñas no coinciden",
          type: "warning",
        });
        return;
      }
      if (this.form.newPassword.length < 8) {
        this.temporalAlert({
          show: true,
          message: "La contraseña debe tener al menos 8 caracteres",
          type: "warning",
        });
        return;
      }
      if (this.form.password === this.form.newPassword) {
        this.temporalAlert({
          show: true,
          message: "La nueva contraseña no puede ser igual a la actual",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const response = await this.services.auth.changePassword({
        password_actual: this.form.password,
        password: this.form.newPassword,
        confirm_password: this.form.confirmPassword,
      });

      if (response.status === 200) {
        this.dialog = false;
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "success",
        });

        this.form.password = "";
        this.form.newPassword = "";
        this.form.confirmPassword = "";
      } else {
        this.temporalAlert({
          show: true,
          message: 'Error al cambiar la contraseña',
          type: "error",
        });
      }

      this.loading = false;
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("utils", {
      sideBar: "sideBar",
    }),
    getFullName() {
      const { nombres, apellidos } = this.userInfo.user || {};
      
      // Extrae el primer nombre y el primer apellido
      const primerNombre = nombres ? nombres.split(" ")[0] : "";
      const primerApellido = apellidos ? apellidos.split(" ")[0] : "";

      return `${primerNombre} ${primerApellido}`.trim();
    },
    getPerfil() {
      return this.userInfo?.perfiles?.nombre
    },
    col1Breakpoint() {
      if (this.$vuetify.breakpoint.xs) {
        if (this.$vuetify.breakpoint.width < 500) {
          return 8
        }else{
          return 7;
        }
      }else{
        return 7
      }
    },
    col2Breakpoint() {
      if (this.$vuetify.breakpoint.xs) {
        if (this.$vuetify.breakpoint.width < 500) {
          return 4
        }else{
          return 5;
        }
      }else{
        return 5
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn__hover {
  &:hover {
    background-color: #f4f7fd !important;
  }
}

.icon__color {
  color: #697182 !important;
}

.circular-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1c1e4d;
  /* Fondo azul */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.circular-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
</style>

<style scoped>
.contenedor-inline {
  display: inline-block; 
  /* Hace que el contenedor se ajuste al ancho del contenido (el botón). */
}

.wrap-text-sub {
  max-width: 228px;        /* Limita el ancho */
  display: block;       /* Asegura que sea un bloque que ocupe 100% del contenedor */
  width: 100%;          /* Ocupa todo el ancho del contenedor padre */
  white-space: normal;  /* Permite saltos de línea */
  word-break: break-word; /* Rompe las palabras largas si exceden el ancho */
  text-align: center;
}
</style>
