import utils from '../modules/utils'
import Vue from "vue";

const hasRole = (role) => {
    return utils.state.roles.some(row=>row===role);
}

Vue.prototype.hasRole=hasRole;

export {
    hasRole
}